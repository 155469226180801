/**
 * Copyright Fitzba Technologies Inc. 2023
 * The NearStores component fetches and displays a list of nearby stores.
 * 
 * Each store is displayed as a NearStoresCard component.
 * 
 * The component uses the useQuery hook from react-query for data fetching 
 * and the useSelector hook from react-redux to get the user's location and information.
 * 
 * The component also provides a horizontal scrolling feature for the list of stores.
 
 * @author Yang Ming
 * @version June 5th, 2023
 */

import './FavoriteStores.css'
import React from 'react'
import StoreCard from '../../zearch/components/StoreCard'
import { useSelector, useDispatch } from 'react-redux'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import { setFilterStoreOnlyOne } from '@reducers/contentReducer'
import { getFavoriteStores } from '@services/api.user.service'
import LoadingSection from '@components/LoadingSection'
import { useQuery } from '@tanstack/react-query'

export default function FavoriteStores() {
    const userinfo = useSelector(state => state.user.userinfo)
    const location = useSelector(state => state.user.location)
    const storesListRef = React.useRef(null)
    const dispatch = useDispatch()

    const {
        isPending,
        // error,
        data,
    } = useQuery({
        queryKey: ['getFavoriteStores', location?.lat, location?.lng],
        queryFn: () => getFavoriteStores({
            lat: location?.lat,
            lng: location?.lng,
            user_id: userinfo?._id,
        }),
    })

    const onClickStore = (storeid) => {
        dispatch(setFilterStoreOnlyOne(storeid))
    }

    const onClickScrollBtn = (step) => {
        if (!storesListRef.current)
            return
        console.log('FavoriteStores storesListRef', storesListRef?.current?.scrollLeft)
        let scrollAmount = 0
        const slideTimer = setInterval(() => {
            storesListRef.current.scrollLeft += step
            scrollAmount += Math.abs(step)
            if (scrollAmount >= 1020) {
                clearInterval(slideTimer)
            }
        }, 50)
    }
    if (isPending)
        return <LoadingSection text='Loading favourite stores' height='200px' />

    const stores = data?.data || []
    if (stores.length === 0)
        return null

    return (
        <div className='relative w-full bg-[#F4F4F4] rounded-[33px] p-6 text-left flex flex-col gap-5' id="favorite-stores">
            <h4 className='uppercase text-[25px] font-normal'>stores</h4>
            <div className="stores-list scroll-wrap" ref={storesListRef}>
                <span
                    className='scroll-left smsize:hidden'
                    style={{
                        top: '130px',
                        left: '20px'
                    }}
                    onClick={() => onClickScrollBtn(-50)}
                >
                    <AiOutlineLeft />
                </span>
                <span className='scroll-right smsize:hidden' style={{ top: '130px', right: '20px' }} onClick={() => onClickScrollBtn(50)}><AiOutlineRight /></span>
                {stores?.map((item) => {
                    return <StoreCard key={item?._id} item={item} onClickStore={onClickStore} location={location} />
                })}
                
            </div>
        </div>
    )
}
