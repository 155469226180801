/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * A component that get and set the user location
 *
 * @author Yang Ming
 * @version May 09, 2023
 */

import React, { useState } from 'react'
import LocationBackground from './LocationBackground'
import { BiMap } from 'react-icons/bi'
import { Popover, message } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { setLocation } from '@reducers/userReducer'
import {
  getLatLngAndAddressFromPostalCode,
  getCurrentPosition
} from '@services/google.map.service'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import { setIsReloadItemsList } from '../reducers/contentReducer'
// import { useJsApiLoader } from '@react-google-maps/api'
// import { Config } from '@Config'

/**
 *
 * @returns A window that houses a mini map that show the user location
 */
export default function Location({
  isMobile
}) {
  //Redux state manager to manage the user location
  const dispatch = useDispatch()
  const location = useSelector(state => state.user.location)
  const [open, setOpen] = useState(false)
  // const [libraries] = useState(['places'])

  // const { isLoaded } = useJsApiLoader({
  //   id: 'google-map-script',
  //   googleMapsApiKey: Config.googleMapsApiKey,
  //   libraries
  // })

  //an async function to get the current location that the user is in
  // useEffect(() => {
  //   if (!isLoaded || location.isLoaded) return
  //   const fetchCurrentPosition = async () => {
  //     try {
  //       const position = await getCurrentPosition()
  //       if (position) {
  //         console.log('handleGetCurrentPosition useEffect', position)
  //         dispatch(setLocation(position))
  //       }
  //     } catch (error) {
  //       console.error('getCurrentPosition error:', error)
  //       message.error('Failed to get current location')
  //     }
  //   }
  //   fetchCurrentPosition()
  // }, [dispatch, location, isLoaded])
  //function to manually change the location
  const handleChangeLocation = async postalCode => {
    try {
      const newLocation = await getLatLngAndAddressFromPostalCode(postalCode)
      console.log('newLocation', newLocation)
      dispatch(
        setLocation({
          ...newLocation,
          postalCode,
        })
      )
      dispatch(setIsReloadItemsList(true))
      message.success('Location updated')
    } catch (error) {
      console.error('handleChangeLocation', error)
      message.error('Postal code is invalid')
    }
  }
  //could this be duplicate?
  const handleGetCurrentPosition = async lat2lng => {
    try {
      const position = await getCurrentPosition(lat2lng)
      if (position) {
        console.log('handleGetCurrentPosition', position)
        dispatch(setLocation(position))
        message.success('Location updated')
      }
    } catch (error) {
      console.error('getCurrentPosition error:', error)
      message.error('Failed to get current location')
    }
  }

  return (
    <div className='header-location smsize:w-fit'>
      <Popover
        placement='bottom'
        title='Location'
        // open={open}
        onOpenChange={setOpen}
        content={
          <LocationBackground
            handleGetCurrentPosition={handleGetCurrentPosition}
            handleChangeLocation={handleChangeLocation}
          />
        }
        trigger='click'
        overlayStyle={{ zIndex: 99999 }}
      >
        <div className='header-address smsize:w-fit'>
          {isMobile ?
            <BiMap size={32} /> :
            <>
              <BiMap size={20} />
              <span className='pr-px text-[14px] location-text whitespace-nowrap'>
                {location?.city}, {location?.province}
              </span>
              {open ? <BsChevronUp size={10} /> : <BsChevronDown size={10} />}
            </>
          }
        </div>
      </Popover>
    </div>
  )
}
