import React from 'react'
import { Form, Input, Select, message } from 'antd'
import EditProfileSection from './EditProfileSection'
import { changeProfile } from 'services/api.service'

export default function PersonalInfo({
    userinfo,

}) {

    const [isUpdatePersonalInfo, setIsUpdatePersonalInfo] = React.useState(false)
    const [personalForm] = Form.useForm()

    const onClickSave = async (values) => {
        try {
            if (values.password) {
                if (values['new-password'] !== values['confirm-password']) {
                    message.error('New password and confirm password do not match!')
                    return
                }
            }
            const res = await changeProfile(userinfo._id, values)
            console.log(res)
            message.success('Profile updated successfully!')
            setIsUpdatePersonalInfo(false)
        } catch (err) {
            console.error('handleSubmit', err)
            message.error('Action failed: ' + err.data?.message || 'Please try again!')
        }
    }

    const onClickCancel = () => {
        setIsUpdatePersonalInfo(false)
    }

    const onClickEdit = () => {
        setIsUpdatePersonalInfo(true)
        if (personalForm) {
            personalForm.setFieldsValue({
                firstName: userinfo?.firstName,
                lastName: userinfo?.lastName,
                phone: userinfo?.phone,
                gender: userinfo?.gender
            })
        }
    }

    return (
        <EditProfileSection
            title='Personal Information'
            isShowEdit={!isUpdatePersonalInfo}
            onClickEdit={onClickEdit}
            contents={
                <>
                    {isUpdatePersonalInfo ? (
                        <Form
                            className='w-full flex flex-col gap-3 text-left'
                            layout="vertical"
                            wrapperCol={{
                                span: 20,
                            }}
                            form={personalForm}
                            onFinish={onClickSave}
                        >
                            <div className='flex smsize:flex-col'>
                                <Form.Item
                                    label='First Name'
                                    name={'firstName'}
                                    rules={[
                                        { required: true, message: 'First name' },
                                    ]}
                                >
                                    <Input className='rounded-full border border-black max-w-[200px] smsize:max-w-none' placeholder='First Name' />
                                </Form.Item>
                                <Form.Item
                                    label='Last Name'
                                    name={'lastName'}
                                    rules={[
                                        { required: true, message: 'Last name' },
                                    ]}
                                >
                                    <Input className='rounded-full border border-black max-w-[200px] smsize:max-w-none' placeholder='Last Name' />
                                </Form.Item>
                            </div>
                            <div className='flex border-t pt-3 smsize:flex-col'>
                                <Form.Item
                                    label='Current Password'
                                    name={'password'}
                                >
                                    <Input.Password className='rounded-full border border-black max-w-[260px] smsize:max-w-none' placeholder='Current password' />
                                </Form.Item>
                                <Form.Item
                                    label='New Password'
                                    name={'newPassword'}
                                >
                                    <Input.Password className='rounded-full border border-black max-w-[260px] smsize:max-w-none' placeholder='New password' />
                                </Form.Item>
                                <Form.Item
                                    label='Confirm Password'
                                    name={'confirmPassword'}
                                >
                                    <Input.Password className='rounded-full border border-black max-w-[260px] smsize:max-w-none' placeholder='Confirm password' />
                                </Form.Item>
                            </div>
                            <div className='flex border-t pt-3 smsize:flex-col'>
                                <Form.Item
                                    label='Phone Number'
                                    name={'phone'}
                                    rules={[
                                        { required: true, message: 'Phone number' },
                                    ]}
                                >
                                    <Input className='rounded-full border border-black max-w-[200px] smsize:max-w-none' placeholder='Phone number' />
                                </Form.Item>
                                <Form.Item
                                    label='Gender'
                                    name={'gender'}
                                    className='w-[200px] smsize:w-full'
                                    initialValue={'N/A'}
                                >
                                    <Select
                                        // size='large'
                                        // bordered={false}
                                        variant="borderless"
                                        className='rounded-full border border-black py-[10px]'
                                        placeholder={'Gender'}
                                        style={{ width: '100%' }}
                                        options={[
                                            {
                                                value: 'N/A',
                                                label: 'N/A'
                                            },
                                            {
                                                value: 'Male',
                                                label: 'Male'
                                            },
                                            {
                                                value: 'Female',
                                                label: 'Female'
                                            }
                                        ]}
                                    />
                                </Form.Item>
                            </div>
                            <div className='flex gap-5 items-center justify-end'>
                                <button onClick={onClickCancel} type='button' className='bg-white text-[#F45045] border rounded-full border-[#F45045] w-[110px]'>Cancel</button>
                                <button type='submit' className='bg-[#E4B456] text-white border border-[#E4B456] rounded-full w-[110px]'>Save</button>
                            </div>
                        </Form>
                    ) : (
                        <div className='w-full max-w-[800px] grid grid-cols-3 gap-4 text-left text-[15px] smsize:text-[13px] smsize:grid-cols-2'>
                            <div className=''>
                                <h4 className='text-[#A0A0A0]'>First Name</h4>
                                <span>{userinfo?.firstName}</span>
                            </div>
                            <div>
                                <h4 className='text-[#A0A0A0]'>Last Name</h4>
                                <span>{userinfo?.lastName}</span>
                            </div>
                            <div>
                                <h4 className='text-[#A0A0A0]'>Email</h4>
                                <span>{userinfo?.username}</span>
                            </div>
                            <div>
                                <h4 className='text-[#A0A0A0]'>Password</h4>
                                <span>******</span>
                            </div>
                            <div>
                                <h4 className='text-[#A0A0A0]'>Phone Number</h4>
                                <span>{userinfo?.phone}</span>
                            </div>
                            <div>
                                <h4 className='text-[#A0A0A0]'>Gender</h4>
                                <span>{userinfo?.gender}</span>
                            </div>
                        </div>
                    )}
                </>
            }
        />
    )
}
