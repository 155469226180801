import React from 'react'
import { DeleteOutlined, EditOutlined,ExclamationCircleFilled } from '@ant-design/icons'
import { Modal } from 'antd';
const { confirm } = Modal;

export default function FavoriteCard({
    item,
    onClickFavoriteCard,
    onClickDeleteFavorite
}) {

    const showDeleteConfirm = () => {
        confirm({
          title: 'Are you sure delete this favourite?',
          icon: <ExclamationCircleFilled />,
          content: 'The favourite will be deleted permanently',
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          onOk() {
            console.log('OK');
            onClickDeleteFavorite(item._id)
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      };

    return (
        <div className='  flex flex-col items-center justify-center'>
            <div className=' relative flex flex-col text-center w-[245px] h-[245px] border rounded-3xl smsize:w-[150px] smsize:h-[150px]'>
                <EditOutlined className=' cursor-pointer absolute right-3 top-4 text-[20px]' onClick={() => onClickFavoriteCard(item)} />
                <DeleteOutlined onClick={showDeleteConfirm} className=' cursor-pointer absolute right-3 top-12 text-[20px] text-red-600' />
                <div className='grid grid-cols-2'>
                    {item.images && item.images.length > 0 ?
                        item.images.map((image, index) => {
                            return <div key={index} className='w-[110px] h-[110px] smsize:w-[65px] smsize:h-[65px] rounded-3xl'>
                                <img className='w-[110px] h-[110px] smsize:w-[65px] smsize:h-[65px] rounded-3xl' src={image} alt="" />
                            </div>
                        }) :
                        <>
                            {Array.from({ length: 4 }, (_, i) => i).map((_, index) => {
                                return <div key={index} className='w-[110px] h-[110px] smsize:w-[65px] smsize:h-[65px] rounded-3xl'>
                                    <img className='w-[110px] h-[110px] smsize:w-[65px] smsize:h-[65px] rounded-3xl' src="/images/user/item-default.png" alt="" />
                                </div>
                            })}
                        </>
                    }
                </div>
            </div>
            <h4 className='text-[20px]'>{item.title}</h4>
            <span className='text-[#727272] text-[14px]'>{item.items.length || 0} items</span>
        </div>
    )
}
