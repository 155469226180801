/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 * A list of API to use throughout the app
 *
 * @author Yang Ming
 * @version May 09, 2023
 */

import { Config } from '../Config'
import axios from 'axios'

const httpClient = axios.create({
  baseURL: Config.apiUrl + Config.apiPrefix,
  timeout: 60 * 1e3,
  // 5000
})

httpClient.interceptors.request.use(config => {
  // Perform logic before sending request
  config.headers['authorization'] =
    `${Config.apiKey} ` + localStorage.getItem('token')
  return config
})

export async function getRecommededSection(code, params) {
  // console.log(itemId, count)
  try {
    const response = await httpClient.get(`/recommendedSection/${code}`, {
      params: params,
    })
    return response.data
  } catch (error) {
    throw error.response
  }
}
export async function getReviews(itemId, count) {
  // console.log(itemId, count)
  try {
    const response = await httpClient.get(`/review`, {
      params: {
        item_id: itemId,
        count: count,
      },
    })
    return response.data
  } catch (error) {
    throw error.response
  }
}

export async function createReview(reviewData) {
  try {
    const response = await httpClient.post(`/review`, reviewData)
    return response.data
  } catch (error) {
    throw error.response
  }
}

export async function submitForm(params) {
  try {
    const res = await httpClient.post(`/submit-form`, params)
    // console.log('postMessage', res.data);
    return res.data
  } catch (error) {
    throw error.response
  }
}
export async function bookDemo(params) {
  try {
    const res = await httpClient.post(`/book-demo`, params)
    // console.log('postMessage', res.data);
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function sendVerificationEmail(params) {
  try {
    const response = await httpClient.post('/user/sendVerificationEmail', params)
    return response.data
  } catch (error) {
    throw error.response.data
  }
}

export async function sendShoppingListEmail(params) {
  try {
    const response = await httpClient.post('/user/sendShoppingListEmail', params)
    return response.data
  } catch (error) {
    throw error.response.data
  }
}

export async function sendRecover(params) {
  try {
    const response = await httpClient.post('/user/recover', params)
    return response.data
  } catch (error) {
    throw error.response.data
  }
}

export async function verifyUser(params) {
  try {
    const response = await httpClient.post('/user/password', params)
    return response.data
  } catch (error) {
    throw error.response.data
  }
}

export async function verifyUserToken(token) {
  try {
    const response = await httpClient.get(`/user/verify/${token}`)
    return response.data
  } catch (error) {
    throw error.response.data
  }
}

export async function getInventoryList(params) {
  try {
    const res = await httpClient.get(`/inventory`, {
      params: params,
    })
    // console.log('postMessage', res.data);
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function getInventoryListV2(params) {
  try {
    const res = await httpClient.get(`/inventoryV2`, {
      params: params,
    })
    // console.log('postMessage', res.data);
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function getInventoryListAlgolia(params) {
  try {
    const res = await httpClient.get(`/inventoryAlgolia`, {
      params: params,
    })
    // console.log('postMessage', res.data);
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function getStoreInventoryList(params) {
  try {
    const res = await httpClient.get(`/vendor/inventory`, { params: params })
    // console.log('postMessage', res.data);
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function getBrandList(params) {
  try {
    const res = await httpClient.get(`/brand/all`, { params: params })
    // console.log('postMessage', res.data);
    return res.data
  } catch (error) {
    throw error.response
  }
}


export async function getBrandRecommendedList(params) {
  try {
    const res = await httpClient.get(`/brand/recommendedList`, { params: params })
    // console.log('postMessage', res.data);
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function inventoryImport(list) {
  try {
    const res = await httpClient.post(`/import`, list)
    // console.log('postMessage', res.data);
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function deleteUser(id) {
  try {
    const res = await httpClient.delete(`/user/${id}`)
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function getStoreById(id) {
  try {
    const res = await httpClient.get(`/business/${id}`)
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function getStoreProfile(id, params) {
  try {
    const res = await httpClient.get(`/business/profile/${id}`, {
      params: params,
    })
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function getItemById(id, params) {
  try {
    const res = await httpClient.get(`/inventory/${id}`, { params: params })
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function getSimilars(id) {
  try {
    const res = await httpClient.get(`/inventory/similars/${id}`)
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function getQuantity(id) {
  try {
    const res = await httpClient.get(`/inventory/quantity/${id}`)
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function registerConsumer(formData) {
  try {
    const res = await httpClient.post(`/user/register`, formData)
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function registerConsumerV3(formData) {
  try {
    const res = await httpClient.post(`/user/registerv3`, formData)
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function getUserinfoByInviteCode(code) {
  try {
    const res = await httpClient.get(`/user/invite/${code}`)
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function registerBusiness(formData) {
  try {
    console.log(formData)
    const res = await httpClient.post(`/vendor/register`, formData)
    return res.data
  } catch (error) {
    throw error.response
  }
}
export async function ssoCheck(params) {
  try {
    const res = await httpClient.get(`/user/sso`, {
      params: params,
    })
    return res.data
  } catch (error) {
    throw error.response
  }
}
export async function consumerToBusiness(formData) {
  try {
    const res = await httpClient.post(`/vendor/consumer`, formData)
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function checkUsername(username) {
  try {
    const res = await httpClient.post(`/user/checkUsername`, {
      username,
    })
    return res.data
  } catch (error) {
    throw error.response
  }
}
export async function checkUser(username) {
  try {
    const res = await httpClient.get(`/user/checkUser`, {
      params: username
    })
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function login(formData) {
  try {
    const res = await httpClient.post(`/user/login`, formData)
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function ssoLogin(formData) {
  try {
    const res = await httpClient.post(`/user/ssoLogin`, formData)
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function ssoLoginGoogle(formData) {
  try {
    const res = await httpClient.post(`/user/ssoLoginGoogle`, formData)
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function ssoLoginApple(formData) {
  try {
    const res = await httpClient.post(`/user/ssoLoginApple`, formData)
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function logout() {
  try {
    const res = await httpClient.post(`/user/logout`)
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function loginToken(token, params) {
  try {
    const res = await httpClient.get(`/user/current/${token}`, {
      params: params,
    })
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function getBusinessTypeList() {
  try {
    const res = await httpClient.get(`/businessType`)
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function getContentByCode(code) {
  try {
    const res = await httpClient.get(`/content/${code}`)
    return res.data
  } catch (error) {
    throw error.response
  }
}

/**
 * add a new record when user access the website
 * @param {url: string, user: string} params
 * @returns
 */
export async function accessRecord(params) {
  try {
    const res = await httpClient.post(`/accessRecord`, params)
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function businessSearch(params) {
  try {
    const res = await httpClient.post(`/registeredBusiness/register`, params)
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function addMessage(formData) {
  try {
    const res = await httpClient.post(`/message`, formData)
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function changeProfile(id, params) {
  try {
    const res = await httpClient.put(`/user/profile/${id}`, params)
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function changePassword(id, params) {
  try {
    const res = await httpClient.put(`/user/password/${id}`, params)
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function getBackground() {
  try {
    const res = await httpClient.get(`/getBackground`)
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function getCarousel() {
  try {
    const res = await httpClient.get(`/carousel`)
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function getFaqList() {
  try {
    const res = await httpClient.get(`/faq`)
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function getNearStores(params) {
  try {
    const res = await httpClient.get(`/business/nearStores`, {
      params: params,
    })
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function getNearProducts(params) {
  try {
    const res = await httpClient.get(`/inventory/nearProducts`, {
      params: params,
    })
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function getfeaturedProducts(params) {
  try {
    const res = await httpClient.get(`/inventory/featuredProducts`, {
      params: params,
    })
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function getRecommendedProducts(params) {
  try {
    const res = await httpClient.get(`/inventory/recommendedProducts`, {
      params: params,
    })
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function getStoreProducts(params) {
  try {
    const res = await httpClient.get(`/inventory/storeProducts`, {
      params: params,
    })
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function getSuggestion(keywords) {
  try {
    const res = await httpClient.get(`/inventory/suggestion`, {
      params: {
        keywords: keywords,
      },
    })
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function getSetting(params) {
  try {
    const res = await httpClient.get(`/setting`, {
      params: params,
    })
    return res.data
  } catch (error) {
    throw error.response
  }
}

export async function appleAuth(params) {
  try {
    const res = await httpClient.post(`/appleAuth`, params)
    return res.data
  } catch (error) {
    throw error.response
  }
}