import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import './Favourite.css'
import FavouriteButton from './components/FavoriteButton'
import FavoriteStores from './components/FavoriteStores'
import FavoriteNewItems from './components/FavoriteNewItems'
import FavoriteItems from './components/FavoriteItems'
import FavoriteList from './components/FavoriteList'
import FavoriteOffers from './components/OfferList'
import { useSelector } from 'react-redux'
import InviteSection from './components/InviteSection'
// import TaskStepItem from './components/TaskStepItem'
import OfferTaskStatus from './components/OfferTaskStatus'
import TopButtons from './components/TopButtons'
import { getOfferTaskStatus } from '@services/api.user.service'

export default function Favourite() {
    const navigate = useNavigate()
    const userinfo = useSelector(state => state.user.userinfo)
    const isMobile = useSelector(state => state.content.isMobile)
    const [status, setStatus] = useState({
        isAllFinished: false,
        favoriteListCount: 0,
        favoriteStoresCount: 0,
        inviteFriendsCount: 0,
        scanQRCode: false,
        downloadApp: false,
        createAccount: true,
        entries: 0
    })

    useEffect(() => {
        const fetchData = async () => {
            const res = await getOfferTaskStatus()
            setStatus(res.data)
        }
        fetchData()
    }, [])

    return (
        <div>
            {isMobile && <TopButtons />}
            <div className='w-full h-fit pb-[80px] smsize:pb-0'>
                <div style={{
                    backgroundImage: `url(${userinfo?.banner ? userinfo?.banner : '/images/user/profile-bg.png'})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat'
                }} className='w-full h-[220px] smsize:h-[150px]'>
                </div>
                <div className='h-fit'>
                    <div className='m-auto w-full max-w-[1440px] smsize:max-w-full smsize:flex-col flex justify-between items-end relative'>
                        {isMobile ?
                            <div className=' absolute top-[-70px] text-left w-full flex justify-between items-center p-3'>
                                <div className='flex flex-col gap-1'>
                                    <div className='flex items-center justify-center uppercase text-[80px] rounded-full w-[100px] h-[100px] border-4 border-[#E4B456] bg-[#F9F1E3] smsize:border-2 smsize:text-[60px]'>
                                        {userinfo?.firstName?.charAt(0)}
                                    </div>
                                    <h1 className='text-[16px] m-0 p-0'>{userinfo?.firstName} {userinfo?.lastName}</h1>
                                    <div className='flex gap-5 text-[#A0A0A0] text-[13px]'>
                                        <p>{status?.favoriteListCount} lists made</p>
                                        <p>{status?.favoriteStoresCount || 0} stores followed</p>
                                    </div>
                                </div>
                                <div>
                                    <FavouriteButton text="Edit profile" onClick={() => navigate('/profile')} />
                                </div>
                            </div> :
                            <div className='flex gap-8 absolute left-0 bottom-[-110px] smsize:static smsize:top-0'>
                                {userinfo?.picture ?
                                    <img src={userinfo.picture} alt="profile" className='w-[100px] h-[100px] rounded-full border-4 border-[#E4B456]' />
                                    :
                                    <div className='flex items-center justify-center uppercase text-[80px] rounded-full w-[160px] h-[160px] smsize:w-[100px] smsize:h-[100px] border-4 border-[#E4B456] bg-[#F9F1E3]'>
                                        {userinfo?.firstName?.charAt(0)}
                                    </div>
                                }
                                <div className='flex flex-col text-left pt-12'>
                                    <h1 className='text-[40px] m-0 p-0'>{userinfo?.firstName} {userinfo?.lastName}</h1>
                                    <div className='flex gap-5 text-[#A0A0A0] text-[13px]'>
                                        <p>{status?.favoriteListCount} lists made</p>
                                        <p>{status?.favoriteStoresCount || 0} stores followed</p>
                                    </div>
                                    <FavouriteButton
                                        text="Edit profile"
                                        onClick={() => navigate('/profile')}
                                    />
                                    {/* <Link to='/profile' className='text-[#A0A0A0] text-[13px]'>Edit Profile</Link> */}
                                </div>
                            </div>
                        }
                        {/* <OfferTaskStatus isHorizontal={false} status={status} /> */}
                    </div>
                </div>
            </div>
            <div className='w-full m-auto max-w-[1440px] smsize:max-w-full flex flex-col gap-10 relative smsize:p-3 smsize:gap-5'>
                {!isMobile &&
                    <div className='h-[100px] flex items-end justify-end'>
                        <TopButtons />
                    </div>
                }
                <FavoriteOffers isMobile={isMobile} />
                <InviteSection userinfo={userinfo} />
                <h1 className='uppercase text-[32px] font-medium text-left smsize:text-[24px] m-0'>
                    Your Favourites
                </h1>
                <FavoriteStores />
                <FavoriteNewItems />
                <FavoriteList status={status} />
                <FavoriteItems isMobile={isMobile} />
            </div>
        </div>
    )
}
