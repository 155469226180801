import React, { useState } from 'react'
import { upload } from 'services/api.user.service'

export default function UserPhoto({
    userinfo
}) {

    const onChangePhotoFile = async (e) => {
        const file = e.target.files[0]
        e.preventDefault()
        const formData = new FormData()
        formData.append('file', file)
        try {
            const res = await upload(file, 'avatar')
            console.log('File uploaded successfully:', res)
        } catch (error) {
            console.error('Error uploading file:', error)
        }
    }

    const onChangeBannerFile = async (e) => {
        const file = e.target.files[0]
        e.preventDefault()
        const formData = new FormData()
        formData.append('file', file)
        try {
            const res = await upload(file, 'banner')
            console.log('File uploaded successfully:', res)
        } catch (error) {
            console.error('Error uploading file:', error)
        }
    }

    const onClickChangePhoto = () => {
        console.log('change photo')
        document.getElementById('change-photo-input').click()
    }
    const onClickChangeBanner = () => {
        console.log('change banner')
        document.getElementById('change-banner-input').click()
    }
    return (
        <div className=' rounded-xl border border-[#E3E3E3] p-6 flex flex-col gap-3'>
            <div className='flex gap-5 items-center'>
                {userinfo?.picture ?
                    <img src={userinfo.picture} alt="profile" className='w-[80px] h-[80px] rounded-full border-2 border-[#E4B456]' />
                    :
                    <div className='w-[80px] h-[80px] rounded-full border-2 border-[#E4B456] bg-[#F9F1E3] flex items-center justify-center'>
                        {userinfo?.firstName?.charAt(0)}
                    </div>
                }
                <input
                    id="change-photo-input"
                    type='file'
                    accept='image/*'
                    onChange={onChangePhotoFile}
                    className='hidden'
                />
                <button onClick={onClickChangePhoto} className=' rounded-full border border-black px-4 h-[30px] text-[13px] smsize:text-[11px]'>
                    change photo
                </button>
            </div>
            <div className='flex gap-5 items-center smsize:flex-col'>
                <img src={userinfo?.banner ? userinfo.banner : '/images/user/profile-bg.png'} alt="profile background" className='w-full max-w-[800px] h-[100px] smsize:max-w-full smsize:justify-start smsize:text-left' />
                <input
                    id="change-banner-input"
                    type='file'
                    accept='image/*'
                    onChange={onChangeBannerFile}
                    className='hidden'
                />
                <button onClick={onClickChangeBanner} className=' rounded-full border border-black px-4 h-[30px] text-[13px] smsize:text-[11px]'>
                    change banner
                </button>
            </div>
        </div>
    )
}
