
import { message, Form } from 'antd'
import React, { useEffect, useState } from 'react'
import CommonModal from '@components/CommonModal'
import { Input } from 'antd'
import { inviteFriend } from '@services/api.user.service'
import { isValidEmail } from '@utils/Helper'

/**
 * A component that displays a popup for reserving an item.
 * 
 * @param {Object} props - The props object.
 * @param {Object} props.item - The item being reserved.
 * @param {boolean} props.opened - A boolean indicating whether the popup is open or not.
 * @param {Function} props.setOpened - A function to set the 'opened' state.
 * @param {Object} props.userinfo - An object containing information about the user.
 * @returns {JSX.Element} The 'ReservePopup' component.
 */
export default function InviteForm({ opened, setOpened, userinfo, isMobile }) {
  const [error, setError] = useState('')
  const [input, setInput] = useState('')

  useEffect(() => {
    setError('')
    setInput('')
  }, [opened])

  function closePopup() {
    setOpened(false)
  }

  function onClickCopylink() {
    navigator.clipboard.writeText(`${process.env.REACT_APP_APP_HOST}register?invitecode=${userinfo.inviteCode}`)
    message.success('Link copied to clipboard')
  }

  function onClickShare() {
    navigator.clipboard.writeText(`${process.env.REACT_APP_APP_HOST}register?invitecode=${userinfo.inviteCode}`)
    message.success('Link copied to clipboard')
  }

  async function onClickInvite() {
    // send email
    setError('')
    if (!input) {
      setError('Please enter an email address')
      return
    }
    // check if email is valid
    if (!isValidEmail(input)) {
      setError('Please enter a valid email address')
      return
    }
    try {
      await inviteFriend({
        email: input
      })
      message.success('Invitation sent successfully')
      setInput('')
    } catch (error) {
      console.log(error)
      setError('Error: ' + error?.data?.message)
    }
  }

  return (
    <CommonModal
      isOpen={opened}
      setIsOpen={setOpened}
      width={'600px'}
      className={`smsize:w-full`}
      children={
        <div className='flex h-fit w-full flex-col items-center justify-center rounded-lg bg-fitzba-white-static font-lexend drop-shadow-2xl sm:w-[500px] lg:w-[600px]'>
          <div className={`flex h-[80px] w-full items-center justify-center rounded-t-lg bg-black`}>
            <img
              src='/fitzba-ICON-WORD-WHITE.png'
              alt='logo'
              className='h-[32px]'
            />
            <div className='absolute top-4 right-5 text-xl text-[#a6a6a6] hover:text-fitzba-bright-gold-static'>
              <button onClick={closePopup}>
                x
              </button>
            </div>
          </div>
          <div className='w-full p-10 flex flex-col gap-5 items-center justify-center text-[#202020] text-center'>
            <h4 className='text-[24px]'>Invite a friend!</h4>
            <p className='w-[360px] text-[18px] font-light'>Gain an extra entry to the giveaway for every friend that joins Fitzba!</p>
            <Input
              value={input}
              onChange={(e) => {
                setInput(e.target.value)
              }}
              className='rounded-full max-w-[400px] border border-[#202020]' size='large' placeholder={`Enter your friend's email`} />
            {error && <p className='text-red-500'>{error}</p>}
            <button onClick={onClickInvite} className='w-[220px] bg-[#E4B456] rounded-full text-white py-2 px-5 text-[16px]'>INVITE</button>
            <div className='w-[400px] my-5 flex items-center justify-center flex-col relative'>
              <div className='border-b w-full border-[#A6A6A6]'></div>
              <div className='px-4 bg-white absolute text-[#A6A6A6]'>Or</div>
            </div>
            <div className='flex gap-5'>
              <button onClick={onClickCopylink} className='w-[190px] smsize:w-[140px] bg-white border border-[#202020] rounded-full text-[#202020] py-2 px-5 text-[16px]'>COPY LINK</button>
              <button onClick={onClickShare} className='w-[190px] smsize:w-[140px] bg-white border border-[#202020] rounded-full text-[#202020] py-2 px-5 text-[16px]'>SHARE</button>
            </div>
          </div>
        </div>
      }
    />
  )
}