/**
 * Copyright Fitzba Technologies Inc. 2023
 *
 *  The Header for the app, containing the logo,
 *  dropdown button and location. once the user login,
 *  their avatar will also display here
 *
 * @author Yang Ming, James Nguyen
 * @version May 09, 2023
 */

import React, { Suspense } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import './Header.css'
import HamburgerDropdown from './HamburgerDropdown'
import CategoriesHeaderNew from '../pages/home/components/CategoriesHeaderNew'
import Location from './Location'
import NavZearchBar from './NavZearchBar'
import { Avatar, Dropdown } from 'antd'
import {
  AiOutlineUser,
  AiOutlineLogin,
  AiOutlineShop,
  AiOutlineBarcode,
  // AiFillHome
} from 'react-icons/ai'
// import { logout } from '@services/api.service'
import { useSelector, useDispatch } from 'react-redux'
import { logout } from '../reducers/userReducer'
import { initItemsFilter } from '../reducers/contentReducer'
import { logout as logoutAPI } from '@services/api.service'
// import CategoriesHeader from '../pages/home/components/CategoriesHeader'
import HeaderShoppingCartIcon from './HeaderShoppingCartIcon'
import { setBackToRoute } from '@reducers/tempReducer'
import { IoCaretDownSharp } from "react-icons/io5"
import DropdownMenuItem from './DropdownMenuItem'
import HeaderMobile from './HeaderMobile'
import LandingLocationNew from '../pages/home/components/LandingLocationNew'

// const US_STATES = usStates.map(item => ({ label: item.code, value: item.name }))

export default function Header() {
  // Redux state manager to manage user info
  const dispatch = useDispatch()
  const userinfo = useSelector(state => state.user.userinfo)
  const isBusiness = useSelector(state => state.user.isBusiness)
  const location = useSelector(state => state.user.location)
  const isMobile = useSelector(state => state.content.isMobile)
  // const [isShowPWA, setIsShowPWA] = React.useState(false)

  const navigate = useNavigate()
  const { pathname } = useLocation()

  if (pathname === '/register' || pathname === '/login') {
    return null
  }

  //async function to handle user logout
  async function onClickLogout() {
    await logoutAPI()
    dispatch(logout())
    navigate('/')
  }
  // the content of the user dropdown
  const items = isBusiness
    ? [
      {
        key: '1',
        label: <Link to='/profile'>User Profile</Link>,
        icon: <AiOutlineUser />,
      },
      {
        key: '2',
        label: (
          <a
            href={`${process.env.REACT_APP_APP_HOST}business`}
            target='_blank'
            rel='noreferrer'
          >
            Business Dashboard
          </a>
        ),
        icon: <AiOutlineShop />,
      },
      // {
      //   key: '3',
      //   label: <Link to='/inventoryImporter'>Inventory importer</Link>,
      //   icon: <AiOutlineBarcode />,
      // },
      // {
      //   key: '4',
      //   label: <Link onClick={() => { setIsShowPWA(true) }}>Add to home screen</Link>,
      //   icon: <AiFillHome />,
      // },
      {
        key: '5',
        label: <Link onClick={onClickLogout}>Logout</Link>,
        icon: <AiOutlineLogin />,
      },
    ]
    : [
      {
        key: '1',
        label: <Link to='/profile'>Profile</Link>,
        icon: <AiOutlineUser />,
      },
      // {
      //   key: '2',
      //   label: <Link onClick={() => { setIsShowPWA(true) }}>Add to home screen</Link>,
      //   icon: <AiFillHome />,
      // },
      {
        key: '3',
        label: <Link onClick={onClickLogout}>Logout</Link>,
        icon: <AiOutlineLogin />,
      },
    ]
  const onClickLogo = () => {
    window.scrollTo(0, 0)
    dispatch(initItemsFilter())
  }

  const onClickLogin = () => {
    console.log('login', pathname)
    // get current url
    const currentUrl = window.location.href
    console.log('onClickLogin', currentUrl)
    // if the current url is not the login page, save the url to local storage
    if (currentUrl.indexOf('/login') === -1) {
      localStorage.setItem('loginUrl', currentUrl)
    }
    dispatch(setBackToRoute(pathname))
  }
  if (isMobile)
    return <HeaderMobile />

  return (
    <div className='sticky top-0 z-[999] w-full' id='header'>
      {/* <PWAPrompt
        appIconPath='/apple-touch-icon.png'
        // isShown={isShowPWA}
      /> */}
      {/* <LandingLocationNew /> */}
      {/* {pathname === '/' && (
        <div className='bg-[#ffedbe] h-[50px] flex items-center justify-center gap-3' style={{
          backgroundImage: 'url(/images/top-giveway-bg.png)',
        }}>
          <h4 className='text-[20px]'>ENTER TO WIN A <strong>$500 SHOPPING SPREE</strong></h4>
          <Link to='/register' className='bg-white rounded-full border-2 border-[#202020] px-3'>JOIN NOW</Link>
        </div>
      )} */}
      <div className='navbar'></div>
      <header className='flex h-fit w-full flex-col bg-fitzba-off-black-static px-3 py-2 text-fitzba-white-static lg:py-0 xl:px-20'>
        <div className='flex h-[80px] flex-row items-center'>
          <div className='mr-3 flex h-fit w-fit items-center text-left sm:hidden'>
            <HamburgerDropdown
              userinfo={userinfo}
              className='mr-5 flex h-fit w-fit items-center text-left sm:hidden'
            />
          </div>
          <div className='flex flex-1 flex-row gap-3 xs:gap-7'>
            <div className='flex flex-row items-center gap-1 xs:gap-4'>
              <Link to='/' onClick={onClickLogo}>
                <img
                  src={isMobile ? 'logo-white-rounded.png' : '/fitzba-WORD-WHITE.png'}
                  alt='Fitzba'
                  className='z-[50] w-[120px] xs:w-[150px] smsize:w-[28px] smsize:h-[28px]'
                />
              </Link>
              {!isMobile && (
                <span>
                  {location?.country === 'US' ?
                    <img src='/images/flag_us.png' alt='Canada Flag' className='xs:pt-[20px]' /> :
                    <img src='/images/flag_ca.png' alt='Canada Flag' className='xs:pt-[20px]' />
                  }
                </span>
              )}
            </div>
            <div className='z-[100] mt-3 h-full 2xs:mt-5'>
              {/* <Suspense fallback={<div>Loading...</div>}> */}
              <Location isMobile={isMobile} />
              {/* </Suspense> */}
            </div>
          </div>
          <div className='hidden flex-1 items-center justify-center gap-[10px] lg:flex 2xl:flex-[2] 4xl:flex-[3]'>
            <NavZearchBar />
          </div>
          <div className='flex w-fit items-center justify-end gap-5 text-right font-light'>
            <Link to='/about' className='hidden md:block'>
              About us
            </Link>
            <Link to='/retail' className='hidden md:block'>
              For Retailers
            </Link>
            {userinfo ? (
              // <Dropdown
              //   menu={{
              //     items,
              //   }}
              //   overlayStyle={{ zIndex: 99999 }}
              //   className='hidden sm:block'
              //   style={{
              //     zIndex: 99999
              //   }}
              // >
              <div className='dropdown bg-white rounded-full py-2 px-3 flex items-center justify-center text-black gap-2 smsize:hidden'>
                <div className='w-[24px] h-[24px] rounded-full border-2 bg-[#7692DB] border-[#1C1C1C] flex items-center justify-center'>
                  {userinfo?.firstName && userinfo.firstName[0]?.toUpperCase()}
                </div>
                <div className='dropbtn flex items-center justify-center gap-1'>
                  <h4>account</h4>
                  <IoCaretDownSharp size={12} />
                </div>
                <div className="dropdown-content w-[160px] bg-white shadow rounded-3xl flex flex-col">
                  <div className='bg-[#DFDFDF] flex justify-between items-center rounded-t-3xl p-5'>
                    <div className='w-[24px] h-[24px] rounded-full bg-[#7692DB] flex items-center justify-center'>
                      {userinfo?.firstName && userinfo.firstName[0]?.toUpperCase()}
                    </div>
                    <h4>{userinfo?.firstName} {userinfo?.lastName}</h4>
                  </div>
                  <div className='flex flex-col p-5 text-left pb-2'>
                    <DropdownMenuItem text="Profile" link="/profile" />
                    <DropdownMenuItem text="Favourites" link="/favourite" />
                    {userinfo?.isBusiness && <DropdownMenuItem
                      text="Dashboard"
                      link={`${process.env.REACT_APP_APP_HOST}business`}
                      onClick={() => { window.open(`${process.env.REACT_APP_APP_HOST}business`, '_blank') }}
                    />}
                    {/* <DropdownMenuItem text="Messages" link="/messages"/> */}
                  </div>
                  <div className='border-t border-[#DFDFDF]' />
                  <div className='flex flex-col p-5 text-left pt-2'>
                    {/* <DropdownMenuItem text="Settings" link="/settings" /> */}
                    <DropdownMenuItem text="Sign out" link="/signout" onClick={onClickLogout} />
                  </div>
                </div>
              </div>

              // </Dropdown>
            ) : (
              <Link to='/login' onClick={onClickLogin}>
                <div className='hidden flex-row items-center gap-3 sm:flex'>
                  <span className='text-[15px] font-semibold'>Login</span>
                </div>
              </Link>
            )}
            <Suspense fallback={<div>Loading...</div>}>
              <HeaderShoppingCartIcon />
            </Suspense>
          </div>
        </div>
        {/* {((pathname === '/') || pathname !== '/') && ( */}
        <div className='flex items-center justify-center gap-2 lg:hidden'>
          <NavZearchBar />
        </div>
        {/* )} */}
      </header>
      {pathname === '/' && (
        <CategoriesHeaderNew />
      )}
      {pathname === '/' && (
        <LandingLocationNew />
      )}
    </div>
  )
}
