import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import { setFilterStoreOnlyOne } from '@reducers/contentReducer'
import { getPromotions } from '@services/api.business.service'
import LoadingSection from '@components/LoadingSection'
import { useQuery } from '@tanstack/react-query'
import OfferItem from './OfferItem'
import TopButtons from './TopButtons'

export default function OfferList({
    isMobile
}) {
    const userinfo = useSelector(state => state.user.userinfo)
    const location = useSelector(state => state.user.location)
    const storesListRef = React.useRef(null)
    const dispatch = useDispatch()

    const {
        isPending,
        // error,
        data,
    } = useQuery({
        queryKey: ['getPromotions'],
        queryFn: () => getPromotions(),
    })

    const onClickStore = (storeid) => {
        dispatch(setFilterStoreOnlyOne(storeid))
    }

    const onClickScrollBtn = (step) => {
        if (!storesListRef.current)
            return
        console.log('FavoriteStores storesListRef', storesListRef?.current?.scrollLeft)
        let scrollAmount = 0
        const slideTimer = setInterval(() => {
            storesListRef.current.scrollLeft += step
            scrollAmount += Math.abs(step)
            if (scrollAmount >= 1020) {
                clearInterval(slideTimer)
            }
        }, 50)
    }
    if (isPending)
        return <LoadingSection text='Loading offers' height='200px' />

    const promotions = data?.data || []
    console.log('FavoriteOffers', promotions)
    // if (promotions.length === 0)
    //     return null

    return (
        <div id="favorite-offers" className='relative w-full bg-[#F4F4F4] rounded-[33px] p-10 text-left flex flex-col gap-5 smsize:p-5 smsize:pr-0'>
            <h4 className='uppercase text-[25px] font-normal smsize:text-[16px]'>Your offers</h4>
            <div className="w-full flex gap-5 scroll-wrap smsize:overflow-x-auto" ref={storesListRef}>
                <span
                    className='scroll-left smsize:hidden'
                    style={{
                        top: '150px',
                        left: '20px'
                    }}
                    onClick={() => onClickScrollBtn(-50)}
                >
                    <AiOutlineLeft />
                </span>
                <span className='scroll-right smsize:hidden' style={{ top: '150px', right: '20px' }} onClick={() => onClickScrollBtn(50)}><AiOutlineRight /></span>
                {promotions.map((offer, index) =>
                    <OfferItem key={index} offer={offer} />
                )}
            </div>
        </div>
    )
}
