/**
 * Copyright Fitzba Technologies Inc. 2023
 * The NearProducts component fetches and displays a list of products that are near the user's location.
 * 
 * Each product is displayed as an Item component.
 * 
 * The component uses the useQuery hook from react-query for data fetching
 * and the useSelector hook from react-redux to get the user's location and information.
 * 
 * The component also provides a horizontal scrolling feature for the list of products.
 *
 * @author Yang Ming
 * @version June 5th, 2023
 */


import React from 'react'
import { useSelector } from 'react-redux'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import './FavoriteNewItems.css'
import ShoppingCartHook from '@hooks/ShoppingCart.hook'
import { getFavoriteItems } from '@services/api.user.service'
import LoadingSection from '@components/LoadingSection'
import { useQuery } from '@tanstack/react-query'
import ItemCard from '../../zearch/components/ItemCard'
import { DownOutlined, UpOutlined } from '@ant-design/icons'

/**
 * NearProducts fetches and displays a list of products that are near the user's location.
 * 
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.setIsShowRecommended - The function to set the visibility of recommended products (no longer used).
 * @param {Array} props.searchHistory - The user's search history.
 * @returns {JSX.Element} The JSX code for the NearProducts component.
 */
export default function FavoriteNewItems() {
    const location = useSelector(state => state.user.location)
    const userinfo = useSelector(state => state.user.userinfo)
    const [isOpen, setIsOpen] = React.useState(true)

    const { addToCart } = ShoppingCartHook()
    const productListRef = React.useRef(null)

    const {
        isPending,
        // error,
        data,
    } = useQuery({
        queryKey: ['getFavoriteItems', 'new', location?.lat, location?.lng],
        queryFn: () => getFavoriteItems({
            lat: location?.lat,
            lng: location?.lng,
            type: 'new'
        }),
    })


    /**
        * onClickScrollBtn horizontally scrolls the list of products by a specified step.
        *
        * @param {number} step - The step size for the scroll.
    */
    const onClickScrollBtn = (step) => {
        if (!productListRef.current)
            return
        let scrollAmount = 0
        const slideTimer = setInterval(() => {
            productListRef.current.scrollLeft += step
            scrollAmount += Math.abs(step)
            if (scrollAmount >= 1000) {
                clearInterval(slideTimer)
            }
        }, 20)
    }

    if (isPending)
        return <LoadingSection text='Loading new items' height='200px' />

    const products = data?.data || []
    if (products.length === 0)
        return null

    return (
        <div id="favorite-new-items" className=' relative w-full bg-[#F4F4F4] rounded-[33px] p-6 text-left flex flex-col gap-5'>
            <div className='flex gap-5 items-center'>
                <h4 className='uppercase text-[25px] font-normal smsize:text-[13px]'>
                    New items from your favourite stores
                </h4>
                <button onClick={() => {
                    setIsOpen(!isOpen)
                }}>
                    {isOpen ? <UpOutlined /> :
                        <DownOutlined />
                    }

                </button>
            </div>
            {isOpen &&
                <div className="product-list text-left scroll-wrap" ref={productListRef}>
                    <span className='scroll-left smsize:hidden' style={{ top: '150px', left: '20px' }} onClick={() => onClickScrollBtn(-50)}><AiOutlineLeft /></span>
                    <span className='scroll-right smsize:hidden' style={{ top: '150px', right: '20px' }} onClick={() => onClickScrollBtn(50)}><AiOutlineRight /></span>
                    {products?.map((item) => {
                        return <ItemCard key={item?._id} userinfo={userinfo} item={item} handleAddToCart={addToCart} />
                    })}
                </div>
            }
            {/* {!userinfo && <div className='mt-[2px] w-full flex justify-end'><Link to='/retail' className=' text-[#0B6186] hover:text-blue-400'>show off your products {' >'}</Link></div>} */}
        </div>
    )
}
