import React, { useState } from 'react'
import { FiPlus } from 'react-icons/fi'
import FavoriteForm from './FavoriteForm'
import { addFavoriteList, updateFavoriteList, getFavoriteList, deleteFavoriteList } from '@services/api.user.service'
import { message } from 'antd'
import { useQuery } from '@tanstack/react-query'
import LoadingSection from '@components/LoadingSection'
import FavoriteCard from './FavoriteCard'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'

export default function FavoriteList({
    status
}) {

    const [open, setOpen] = useState(false)
    const [currentItem, setCurrentItem] = useState(null)
    const [isRefresh, setIsRefresh] = useState(false)
    const favoriteListRef = React.useRef(null)

    const handleAddFavorite = async (data) => {
        try {
            let res = data._id ? await updateFavoriteList(data._id, data) : await addFavoriteList(data)
            console.log('res', res)
            message.success('Operation successful')
            setOpen(false)
            setIsRefresh(!isRefresh)
        } catch (error) {
            console.log('error', error)
            message.error('Failed: ' + error?.data?.message)
        }
    }

    const {
        isPending,
        // error,
        data,
    } = useQuery({
        queryKey: ['getFavoriteList', isRefresh],
        queryFn: () => getFavoriteList(),
    })

    const onClickScrollBtn = (step) => {
        if (!favoriteListRef.current)
            return
        let scrollAmount = 0
        const slideTimer = setInterval(() => {
            favoriteListRef.current.scrollLeft += step
            scrollAmount += Math.abs(step)
            if (scrollAmount >= 1020) {
                clearInterval(slideTimer)
            }
        }, 50)
    }

    if (isPending)
        return <LoadingSection text='Loading favorite lists' height='200px' />

    const favoriteList = data?.data || []

    const onClickFavoriteCard = (item) => {
        setCurrentItem(item)
        setOpen(true)
    }

    const onClickDeleteFavorite = async (id) => {
        try {
            let res = await deleteFavoriteList(id)
            console.log('res', res)
            message.success('Operation successful')
            setIsRefresh(true)
        } catch (error) {
            console.log('error', error)
            message.error('Failed: ' + error?.data?.message)
        }
    }

    return (
        <>
            <FavoriteForm open={open} setOpen={setOpen} onSubmit={handleAddFavorite} currentItem={currentItem} />
            <div id="favorite-list" className='w-full text-left flex flex-col gap-5'>
                <h4 className='uppercase text-[25px] font-normal smsize:text-[15px]'>Lists</h4>
                <div className='w-full flex gap-3'>
                    <button
                        onClick={() => {
                            if (status.favoriteStoresCount === 0) {
                                message.error('Please add a store to your favorite list first')
                                return
                            }
                            setOpen(true)
                        }}
                        className='rounded-[33px] bg-black text-white px-14 h-[245px] flex items-center justify-center smsize:px-3 smsize:py-3 smsize:h-[155px]'>
                        <FiPlus size={128} className='smsize:w-[100px]' />
                    </button>
                    <div className='relative overflow-x-hidden'>
                        <div className='flex gap-5 stores-list scroll-wrap' ref={favoriteListRef}>
                            <span
                                className='scroll-left smsize:hidden'
                                style={{
                                    top: '110px',
                                    left: '20px'
                                }}
                                onClick={() => onClickScrollBtn(-50)}
                            >
                                <AiOutlineLeft />
                            </span>
                            <span className='scroll-right smsize:hidden' style={{ top: '110px', right: '20px' }} onClick={() => onClickScrollBtn(50)}><AiOutlineRight /></span>
                            {favoriteList.map((item) => {
                                return <FavoriteCard 
                                key={item._id} 
                                item={item} 
                                onClickFavoriteCard={onClickFavoriteCard} 
                                onClickDeleteFavorite={onClickDeleteFavorite}
                                />
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
