import React, { useEffect, useState, useRef } from 'react'
import { IoIosSearch } from 'react-icons/io'
import StoreFilter from './StoreFilter'
import DealProducts from './components/DealProducts'
import NewItemsProducts from './components/NewItemsProducts'
import AllProducts from './components/AllProducts'
import { setStoreProfileFilter } from 'reducers/contentReducer'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'
import { Input } from 'antd'
import StoreProfileBrand from './components/StoreProfileBrand'
import StoreProfileCategory from './components/StoreProfileCategory'
import FilterSideSelectedMobile from './components/FilterSideSelectedMobile'

export default function StoreMain({
    isMobile,
    business,
    location
}) {

    const [width, setWidth] = useState(0) // State to store the width
    const productRef = useRef(null)

    const dispatch = useDispatch()
    const storeProfileFilter = useSelector(state => state.content.storeProfileFilter)
    const storeProfileList = useSelector(state => state.content.storeProfileList)
    const [input, setInput] = React.useState(storeProfileFilter.title || '')
    const [isOpenBrand, setIsOpenBrand] = React.useState(false)
    const [isOpenCategory, setIsOpenCategory] = React.useState(false)

    useEffect(() => {
        // Function to update the width
        const updateWidth = () => {
            if (productRef.current) {
                console.log(productRef.current.offsetWidth)
                setWidth(productRef.current.offsetWidth) // Get the width using offsetWidth
            }
        }
        // Update the width when the component mounts
        updateWidth()
        // Optionally, update the width when the window is resized
        window.addEventListener('resize', updateWidth)
        // Cleanup the event listener on unmount
        return () => window.removeEventListener('resize', updateWidth)
    }, [])

    useEffect(() => {
        setTimeout(() => {
            setInput(storeProfileFilter.title)
        }, 1000)
    }, [storeProfileFilter.title])

    const handleChange = debounce((value) => {
        console.log('onSearch', value)
        dispatch(setStoreProfileFilter({
            ...storeProfileFilter,
            title: value
        }))
    }, 600)
    let isSearch = false
    if (storeProfileFilter.title || storeProfileFilter.brands.length > 0 || storeProfileFilter.categories.length > 0) {
        isSearch = true
    }

    console.log('business', business)

    const onClickBrandItem = (brand_id) => {
        dispatch(setStoreProfileFilter({
            ...storeProfileFilter,
            brands: [brand_id]
        }))
        setIsOpenBrand(false)
    }

    const onClickCategoryItem = (id) => {
        console.log('id', id)
        dispatch(setStoreProfileFilter({
            ...storeProfileFilter,
            categories: [id]
        }))
        setIsOpenCategory(false)
    }

    return (
        <div className='w-full flex h-fit bg-white smsize:flex-col'>
            <StoreProfileBrand
                isOpen={isOpenBrand}
                setIsOpen={setIsOpenBrand}
                dispatch={dispatch}
                onClickBrandItem={onClickBrandItem}
                itemsFilter={storeProfileFilter}
                brands={business?.brands}
            />
            <StoreProfileCategory
                isOpen={isOpenCategory}
                setIsOpen={setIsOpenCategory}
                dispatch={dispatch}
                onClickCategoryItem={onClickCategoryItem}
                itemsFilter={storeProfileFilter}
                categories={business?.categories}
            />
            <StoreFilter business={business} isMobile={isMobile} />
            <div ref={productRef} id='store-main-product' className='flex-2 w-full py-[35px] px-[65px] flex flex-col gap-3 smsize:p-5'>
                <FilterSideSelectedMobile
                    brands={business?.brands}
                    categories={business?.categories}
                    storeProfileFilter={storeProfileFilter}
                    isMobile={isMobile}
                    dispatch={dispatch}
                />
                <div className='w-full flex justify-between items-center smsize:flex-col smsize:gap-2 smsize:justify-start smsize:items-start'>
                    <div className='flex gap-3'>
                        <button
                            onClick={() => setIsOpenBrand(!isOpenBrand)}
                            className='border border-black px-4 rounded-full py-1'>
                            brands
                        </button>
                        <button
                            onClick={() => setIsOpenCategory(!isOpenCategory)}
                            className='border border-black px-4 rounded-full py-1'>
                            categories
                        </button>
                    </div>
                    <Input
                        allowClear
                        prefix={<IoIosSearch size={24} />}
                        className=' rounded-full max-w-[500px]'
                        placeholder='Search items'
                        onChange={(e) => handleChange(e.target.value)}
                    />
                </div>
                <div className='flex text-left justify-between items-center smsize:flex-col smsize:gap-5 smsize:items-start smsize:mb-5'>
                    <div className='text-[13px] uppercase smsize:text-[12px]'>
                        Found <strong>{storeProfileList.total}</strong> Local results {' '}
                        {storeProfileFilter?.title &&
                            <>
                                for <strong>"{storeProfileFilter.title}"</strong>
                            </>
                        }
                    </div>
                    <div className='smsize:text-[12px] flex items-center justify-between gap-1 border rounded-full px-2 py-1'>
                        <h3>Sort by:</h3>
                        <select className='border-none font-light' onChange={(e) => {
                            dispatch(setStoreProfileFilter({
                                ...storeProfileFilter,
                                sortBy: e.target.value
                            }))
                        }}>
                            <option value="latest">Latest</option>
                            <option value="low-to-high">Low to High</option>
                            <option value="high-to-low">High to Low</option>
                        </select>
                    </div>
                </div>
                {!isSearch &&
                    <>
                        <DealProducts business={business} width={width} isMobile={isMobile} />
                        <NewItemsProducts business={business} width={width} isMobile={isMobile} />
                    </>
                }
                <AllProducts business={business} isMobile={isMobile} />
            </div>
        </div>
    )
}
